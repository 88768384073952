import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './utils/drag.js';
import { DataList } from "./utils/api/http.js";
import { GetUrl, Setitem } from "./utils/utils.js";
import { setStorage, getStorage,removeToken } from './utils/token/token.js';
Vue.config.productionTip = false;
Vue.prototype.$bus = new Vue();
const RoomID=GetUrl('roomId');
const SecneId = GetUrl('SecneId');
// const RoomID = window.location.href.slice(window.location.href.indexOf('&roomId=') + 8, window.location.href.length + 26);
// const SecneId = window.location.href.slice(window.location.href.indexOf('&SecneId=') + 10, window.location.href.length - 27);
// const exempt = window.location.href.slice(window.location.href.indexOf('&a=') + 3, window.location.href.indexOf('&a=') + 8);
// 判断当前是不是通过分享链接进入场景的
// if (window.location.href.indexOf('?') != -1) {
//   if (SecneId) {
//     // var SecneID=paramRequest['SecneId']
//     let data = {
//       composition: {
//         userId: "",
//         phone: "",
//         compositionId: SecneId,
//       },
//       pageNum: 1,
//       pageSize: 1,
//     }
//     DataList(data).then(res => {
//       console.log(res,'新数据新数据新数据新数据新数据新数据新数据新数据');
//       setStorage('UrlDAta', res.data.list[0].modelInformation);
//       setStorage('SecneId', res.data.list[0].compositionId);
//       setStorage('SecneType', res.data.list[0].modelType);
//       setStorage('roomId', res.data.list[0].roomId);
//     })
//   };

// };
// 是都需要免登录流程
if (GetUrl('a') == 'false') {
  let code = THREE.MathUtils.generateUUID()
  setStorage('UserId', code);//人物个人ID
  setStorage('UserName', '测试' + Math.floor(Math.random() * 1001) + '号' + code.substring(code.length - 6, code.length));//性别
  let hex = Math.floor(Math.random() * (5 - 1 + 1)) + 1;
  setStorage('Garment', hex);   //衣服
  let Num = Math.floor(Math.random() * 10 + 1);
  if (Num % 2 == 1) {
    setStorage('Sex', 1);
  } else {
    setStorage('Sex', 0);      //随机性别
  }

}
else {
  console.log();
  const whiteRouter = ['/Login'];
  router.beforeEach((to, from, next) => {
    if (getStorage('token')) {
      next();
    } else {
      if (whiteRouter.indexOf(to.path) !== -1) {
        next();
      } else {
        next({
          path: '/Login',
          query: {
            roomId: RoomID,
            SecneId: SecneId
          }
        });
      }
    }
  })
}
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
