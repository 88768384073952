import axios from 'axios';
import { getStorage } from "../token/token";

var request = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    // baseURL: 'http://192.168.1.14:8090/',//本地接口服务地址
    // baseURL: 'https://meetings.shinebim.com:8898/dev-api/',//---------服务器测试接口服务地址
    // baseURL: 'https://meetings.shinebim.com:8899/dev-api/',//服务器接口服务地址
    baseURL: 'https://qtjdyzchat.pgacg.com/jdyz/server/',

    timeout: 30000,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
});
// Add a request interceptor
request.interceptors.request.use(function (config) {
    if (getStorage('token')) {
        config.headers.token = getStorage('token')
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

request.interceptors.response.use(function (response) {
    if (response.status == 200) {
        return response.data
    } else {
        alert('服务器出错')
    }
    return;
}, function (error) {
    return Promise.reject(error);
});

export default request; //暴露axios实例
