import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
import Preview from "../components/component/preview.vue";
import Index from "../views/index.vue";
import Login from "../views/login/login.vue";
import Role from "../views/role/role.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: '/Index',
    name: 'Index',
    component: Index,
    meta: {
      title: '场景列表界面'
    },
  },
  {
    path: '/',
    redirect: '/Login',
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login,
    meta: {
      title: '登录'
    }
  },
  {
    path: '/Role',
    name: 'Role',
    component: Role,
    meta: {
      title: '角色选择'
    }
  },
  {
    path: '/HomeView',
    name: 'home',
    component: HomeView,
    meta: {
      title: '场景编辑界面'
    }
  },

  {
    path: '/Preview',
    name: 'Preview',
    component: Preview,
    meta: {
      title: '预览界面'
    }
  },
]


const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
export default router
